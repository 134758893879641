import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../_helpers";
import { FaExternalLinkAlt,FaInfoCircle} from "react-icons/fa";

function numberWithCommas(x) {
    return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export const MockDocTableColumn = (conversionrate,symbol,history,language) => [
    {
        Header: language.HOLD_DIAMOND_REQUESTNUMBER,
        className: "align-middle text-center",
        accessor: (row) => {
            return (
                <>
                    <span>{row.conform_good_id}</span>
                    <p className="m-0 small text-muted">{row.created_date}</p>
                </>
            );
        },
    },
    {
        Header: language.HOLD_DIAMOND_DIAMONDDETAILS,
        accessor: (d) => (
            <>
                <div className="row">
                    <div className="col-2 text-center p-0">
                        {d.aws_image ? (
                            <img
                                className="img-fluid mr-2"
                                alt={d.C_Shape}
                                src={d.aws_image}
                                width="50px"
                            />
                        ) : (
                            <img
                                className="img-fluid mr-2"
                                alt={d.C_Shape}
                                src={toAbsoluteUrl(`/media/shape/${d.C_Shape.toLowerCase()}-no.png`)}
                                width="50px"
                                style={{ backgroundColor: "#f4f4f4" }}
                            />
                        )}
                    </div>
                    <div className="col-10 pl-2 p-0 text-left">
                        <div className={`mb-1 ${d.diamond_type === "N" ? "natTab" : "labTab"}`}>
                            <span
                                className={`${
                                    d.diamond_type === "N"
                                        ? d.C_Color === "fancy"
                                            ? "F"
                                            : "W"
                                        : d.C_Color === "fancy"
                                        ? "LF"
                                        : "L"
                                }_diamond mr-3 small`}
                            >
                                {d.diamond_type === "N"
                                    ? d.C_Color === "fancy"
                                        ? "F"
                                        : "W"
                                    : d.C_Color === "fancy"
                                    ? "LF"
                                    : "L"}
                            </span>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                className="text-primary mx-2 small"
                                href={
                                    d.certificate_link
                                }
                            >
                                {d.Lab}-{d.Certi_NO}
                            </a>
                            <span onClick={() => history.push({ pathname: `/detail/${d.diamond_type === "N" ? "N" : "L"}-${d.id}` })} className={`text-primary cursor-pointer small ml-2`}>
                                {d.diamond_type === "N" ? "N" : "L"}-{d.id}
                                {d.hold_comments?<OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip">
                                                           {d.hold_comments}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span className="ml-2 text-hover-primary cursor-pointer"><FaInfoCircle /></span>
                                                </OverlayTrigger>:""}
                            </span>
                        </div>

                        <span className="text-dark font-weight-bold d-block">
                            {d.C_Shape}{" "}
                            {d.C_Weight}{" "}
                            {d.C_Color === "fancy" ? (
                                <>
                                    {d.f_intensity}{" "}{d.f_overtone}{" "}{d.f_color}{" "}
                                </>
                            ) : (
                                <>{d.C_Color}{" "}</>
                            )}
                            {d.C_Clarity}{" "}{d.C_Cut}{" "}{d.C_Polish}{" "}{d.C_Symmetry}{" "}
                            {d.C_Fluorescence}
                        </span>
                    </div>
                </div>
            </>
        ),
        className: "text-center align-middle",
        disableSortBy: true,
    },
    // {
    //     Header:'Tag',
    //     className:"text-center align-middle mr-3",
    //     Cell: (row) => {
    //         return  <div style={{ maxWidth: "180px"}}>
    //             <div className="label label-outline-primary label-pill label-inline bg-primary-o-20 mr-1">NO SHADE</div>
    //             <div className="label label-outline-success label-pill label-inline bg-success-o-20 mr-1">EYECLEAN</div>
    //             <div className="label label-outline-info label-pill label-inline bg-info-o-20 mr-1">MILKY</div>
    //         </div>;
    //       }
    // },
    // {
    //     Header: "Location",
    //     accessor: (d) => (
    //         <>
    //             <span calssName="font-weight-bolder">{d.country}</span>
    //             <br />
    //             <span className="text-muted small">{d.shippingdays} days</span>
    //         </>
    //     ),
    //     className: "text-center align-middle",
    //     disableSortBy: true,
    // },
    {
        Header: language.HOLD_DIAMOND_HOLDTIME,
        accessor: (d) => (
            <>
                <span calssName="font-weight-bolder">{d.hold_time} Hours</span>
                
            </>
        ),
        className: "text-center align-middle",
        disableSortBy: true,
    },
    {
        Header: language.HOLD_DIAMOND_STATUS,
        className: "text-center align-middle",
        accessor: (d) => {
            return (
                <div>
                    <div
                        className={`label ${
                                  d.confirm_status === "Pending" ? "label-warning"
                                : d.confirm_status === "Rejected" ? "label-danger" 
                                : d.confirm_status === "Approved" ? "label-success" 
                                : ""
                        } label-inline  mr-2`}
                    >
                        {d.confirm_status}
                    </div>
                </div>
            );
        },
    },
    // {
    //     Header:'Diamond Journey',
    //     accessor:d => (
    //         <>
    //             <span calssName="font-weight-bolder">{d.canada_mark   }</span><br />
    //             <span className="text-muted small">1-2 days</span>
    //         </>
    //       ),
    //     className:"text-center align-middle",
    // },
    {
        Header: language.HOLD_DIAMOND_DISC,
        accessor: (d) => Number(d["Rap%"]).toFixed(2),
        sortMethod: (a, b) => Number(a) - Number(b),
        Cell: (row) => {
            return <span className={`${(row.value >= 0) ? 'text-success' : 'text-danger'}`}>{row.value}%</span>;
        },
    },
    {
        Header: language.HOLD_DIAMOND_OURPRICEUSD,
        accessor: (d) => (
            <>
                <span calssName="font-weight-bolder">
                    ${numberWithCommas(parseFloat(d["OurPrice(USD)"]))}
                </span>{" "}
                <br />
                <span className="text-muted small">
                $/CT {numberWithCommas(parseFloat(d["USD/CT"]))}
                </span>
            </>
        ),
        className: "text-center align-middle",
    },
    // {
    //     Header: language.HOLD_DIAMOND_OURPRICEINR,
    //     accessor: (d) => (
    //         <>
    //             <span calssName="font-weight-bolder">
    //                 ₹{numberWithCommas(parseFloat(d["OurPrice(INR)"]))}
    //             </span>{" "}
    //             <br />
    //             <span className="text-muted small">
    //                 ₹/CT {numberWithCommas(parseFloat(d["INR/CT"]))}
    //             </span>
    //         </>
    //     ),
    //     className: "text-center align-middle",
    // },
    // {
    //     Header:'Payment Status',
    //     accessor:'CUT',
    //     className:"text-center align-middle",
    // }
];
