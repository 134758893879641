import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { BsCardImage, BsFillCameraVideoFill } from "react-icons/bs";
import { FaAward } from "react-icons/fa";
import { toAbsoluteUrl } from "../../../_helpers";
import { ImagePopup } from "../../widgets/tiles/ImagePopUp";
import { VideoPopup } from "../../widgets/tiles/VideoPopUp";

function numberWithCommas(x) {
    return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const MockSearchResultColumn = (conversionrate, user,symbol,GetRatio,language) => [
    {
        Header: language.SEARCHLIST_STOCKID ,
        accessor: (d) => (
            <>
                <span>
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id="tooltip">
                                {d.availability === "Memo" ||
                                d.availability === "Hold"
                                    ? `Business Process`
                                    : d.availability === "Guaranteed"
                                    ? `Guaranteed`
                                    : d.availability === "Not Available"
                                    ? `Not Available`
                                    : d.availability === "Subject to Prior sale"
                                    ? `Subject to Prior sale`
                                    : `Unknown`}
                            </Tooltip>
                        }
                    >
                        <img
                            className="img-fluid availIco mr-2"
                            alt={d.availability}
                            src={toAbsoluteUrl(
                                `/media/svg/${
                                    d.availability === "Memo" ||
                                    d.availability === "Hold"
                                        ? `availability_primary.svg`
                                        : d.availability === "Guaranteed"
                                        ? `availability_success.svg`
                                        : d.availability === "Not Available"
                                        ? `availability_danger.svg`
                                        : d.availability ===
                                          "Subject to Prior sale"
                                        ? `availability_warning.svg`
                                        : `availability_blank_white.svg`
                                }`
                            )}
                        />
                    </OverlayTrigger>
                </span>
                <span className="text-primary">
                    {" "}
                    {d.diamond_type}-{d.id}
                </span>
            </>
        ),
        className: "align-middle text-center",
    },
    {
        Header: language.SEARCHLIST_LOCATION ,
        accessor: "country",
        className: "text-center align-middle",
        Cell: (row) => {
            return (
                <>
                    {row.value ? (
                        <OverlayTrigger
                            placement="top"
                            className="text-capitalize"
                            overlay={
                                <Tooltip id="tooltip">{row.value}</Tooltip>
                            }
                        >
                            <img
                                className="img-fluid flagIco mr-4"
                                alt={row.value}
                                src={toAbsoluteUrl(
                                    `/media/map/${row.value.toLowerCase()}.png`
                                )}
                            />
                        </OverlayTrigger>
                    ) : null}
                </>
            );
        },
    },
    {
        Header: language.SEARCHLIST_MEDIA ,
        accessor: (d) => (
            <>
                <div className="d-flex justify-content-center align-items-center">
                    {d.aws_image ? (
                        <span>
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id="tooltip">Image</Tooltip>}
                            >
                                <ImagePopup
                                    Image={d.aws_image}
                                    Shape={d.C_Shape}
                                />
                                {/* <a className="text-primary mx-2 text-center" href={d.aws_image}><BsCardImage/></a> */}
                            </OverlayTrigger>
                        </span>
                    ) : (
                        <span style={{ width: "27px" }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                    )}
                    {d.video ? (
                        <span>
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id="tooltip">Video</Tooltip>}
                            >
                                <VideoPopup
                                    ProductId={d.id}
                                    WLDiamondType={d.diamond_type}
                                    Certi_No={d.Certi_NO}
                                    Video={d.video}
                                />
                                {/* <a className="text-primary mx-2 text-center" href={d.video}><BsFillCameraVideoFill/></a> */}
                            </OverlayTrigger>
                        </span>
                    ) : (
                        <span style={{ width: "27px" }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                    )}
                    {d.Certi_NO ? (
                        <span>
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltip">Certificate</Tooltip>
                                }
                            >
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    className="text-primary mx-2 text-left"
                                    href={
                                        d.certificate_link
                                    }
                                >
                                    <FaAward />
                                </a>
                            </OverlayTrigger>
                        </span>
                    ) : (
                        <span style={{ width: "27px" }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                    )}
                </div>
            </>
        ),
        className: "text-center align-middle",
        disableSortBy: true,
    },
    {
        Header: language.SEARCHLIST_SHAPE,
        accessor: (d) => (
            <>
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip">{d.C_Shape}</Tooltip>}
                >
                    <SVG
                        width="25px"
                        height="25px"
                        src={toAbsoluteUrl(
                            `/media/shape/svg_shape/${d.C_Shape.toLowerCase()}.svg`
                        )}
                    />
                </OverlayTrigger>
            </>
        ),
        className: "text-center align-middle",
    },
    {
        Header: language.SEARCHLIST_CARAT ,
        accessor: "C_Weight",
        className: "text-center align-middle",
        Cell: (row) => {
            return <>{row.value.toFixed(2)}</>;
        },
    },
    {
        Header: language.SEARCHLIST_COLOR,
        accessor: (d) => (
            <>
                <OverlayTrigger
                    placement="top"
                    overlay={
                        <Tooltip id="tooltip">
                            {d.C_Color && d.C_Color.toLowerCase() === "fancy" ? (
                                <>
                                    {d.f_intensity}{" "}{d.f_overtone}{" "}{d.f_color}
                                </>
                            ) : (
                                d.C_Color
                            )}
                        </Tooltip>
                    }
                >
                    <span
                        className="d-inline-block text-truncate text-dark"
                        style={{ maxWidth: "80px" }}
                    >
                        {d.C_Color && d.C_Color.toLowerCase() === "fancy" ? (
                            <>
                                {d.f_intensity}{" "}{d.f_overtone}{" "}{d.f_color}{" "}
                            </>
                        ) : (
                            d.C_Color
                        )}
                    </span>
                </OverlayTrigger>
            </>
        ),
        minwidth: 50,
        className: "text-center align-middle",
    },
    {
        Header: language.SEARCHLIST_CLARITY,
        accessor: "C_Clarity",
        className: "text-center align-middle",
    },
    {
        Header: language.SEARCHLIST_CUT,
        accessor: "C_Cut",
        className: "text-center align-middle",
        Cell: (row) => {
            return <>{row.value ? row.value : "-"}</>;
        },
    },
    {
        Header: language.SEARCHLIST_POL,
        accessor: "C_Polish",
        className: "text-center align-middle",
    },
    {
        Header: language.SEARCHLIST_SYMM ,
        accessor: "C_Symmetry",
        className: "text-center align-middle",
    },
    {
        Header: language.SEARCHLIST_FLOU,
        accessor: "C_Fluorescence",
        className: "text-center align-middle",
    },
    {
        Header: language.SEARCHLIST_LAB,
        accessor:"Lab",
        className: "text-center align-middle",
    },
    {
        Header: symbol === "$" ? "$/CT":"INR/CT",
        className: "text-right align-middle pr-5",
        accessor:"OurRate",
        Cell: (d) => (
            <>
                {
                        conversionrate !== 1 ? <>
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltip">
                                        ${numberWithCommas(d.value)}
                                    </Tooltip>
                                }
                            >
                                <span> {symbol}{numberWithCommas(d.value * conversionrate)}</span>
                            </OverlayTrigger>
                        </> :
                        <span> {symbol}{numberWithCommas(d.value * conversionrate)}</span>
                    }
            </>
        ),
        sortMethod: (a, b) => Number(a) - Number(b),
    },
    {
        Header: language.SEARCHLIST_OURPRICE,
        className: "text-right align-middle pr-5",
        accessor:"OurPrice",
        Cell: (row) => (
            <>
                {
                    conversionrate !== 1 ? <>
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="tooltip">
                                    ${numberWithCommas(row.value)}
                                </Tooltip>
                            }
                        >
                            <span> {symbol}{numberWithCommas(row.value * conversionrate)}</span>
                        </OverlayTrigger>
                    </>:
                    <span> {symbol}{numberWithCommas(row.value * conversionrate)}</span>
                }
            </>
        ),
        sortMethod: (a, b) => Number(a) - Number(b),
    },
    {
        Header: language.SEARCHLIST_DISC,
        className: "text-center align-middle",
        accessor: (d) => Number(d.discount_main).toFixed(2),
        sortMethod: (a, b) => Number(a) - Number(b),
        Cell: (row) => {
            return (
                <>
                {row.value ? <span className={`${(row.value >= 0) ? 'text-success' : 'text-danger'}`}>{row.value}%</span> : <span className={`text-success`}>0.00%</span>}
                </>
            );
        },
    },
    {
        Header: language.SEARCHLIST_RATIO,
        className: "text-center align-middle",
        accessor: (row) => (
            <>
                {GetRatio(row)}
            </>
        ),
        sortMethod: (a, b) => Number(a) - Number(b),
    },
    // {
    //     Header: "Tag",
    //     className: "text-center align-middle mr-3",
    //     Cell: (row) => {
    //         return (
    //             <div style={{ maxWidth: "180px" }}>
    //                 <div className="label label-outline-primary label-pill label-inline bg-primary-o-20 mr-1">
    //                     NO SHADE
    //                 </div>
    //                 <div className="label label-outline-success label-pill label-inline bg-success-o-20 mr-1">
    //                     EYECLEAN
    //                 </div>
    //                 <div className="label label-outline-info label-pill label-inline bg-info-o-20 mr-1">
    //                     MILKY
    //                 </div>
    //             </div>
    //         );
    //     },
    // },
    {
        Header: language.SEARCHLIST_MEAS,
        accessor: (row) => (
            <>
                {row.C_Length?row.C_Length.toFixed(2):"0.00"}*{row.C_Width?row.C_Width.toFixed(2):"0.00"}*{row.C_Depth?row.C_Depth.toFixed(2):"0.00"}
            </>
        ),
        className: "text-center align-middle",
    },
    {
        Header: language.SEARCHLIST_TABLE,
        accessor: "C_TableP",
        className: "text-center align-middle",
        Cell: (row) => {
            return <>{row.value.toFixed(2)}% </>;
        },
    },
    {
        Header: language.SEARCHLIST_DEPTH,
        accessor: "C_DefthP",
        className: "text-center align-middle",
        Cell: (row) => {
            return <>{row.value.toFixed(2)}% </>;
        },
    },
    {
        Header: language.SEARCHLIST_CERTIFICATE,
        accessor: (row) => (
            <>
                {row.Certi_NO? (
                    <a
                        target="_blank"
                        rel="noreferrer"
                        className="text-primary mx-2 text-left"
                        href={
                            row.certificate_link
                        }
                    >
                        {row.Certi_NO}
                    </a>
                ) : ("-")}
            </>
        ),
        className: "text-center align-middle",
    },
];
