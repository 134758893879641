import React, { useEffect, useState } from "react";
import { Field } from "formik";
import { NODE_API_URL } from "../../../../../env_config";
import axios from "axios";
export const Lab = ({language}) => {
    const [LabData,setLabData] = useState([])
    useEffect(() => {
            axios({
                method: "post",
                url: `${NODE_API_URL}/TESTMODE/getAllLab`,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                },
                data: {
                    "diamondType": "L"
                }
            })
                .then(function (res) {
                    if (res.data.message === "UnAuthorized") {
                        localStorage.clear();
                        window.location.reload(true);
                    }
                    if (res && res.data.success && res.data.data) {
                        const options = res.data.data.map((item,index) => ({
                            id: index + 1,
                            lab: item.Lab
                        }));
                        setLabData(options);
                    }
                })
                .catch(function (res) {
                    if (res.data.message === "UnAuthorized") {
                        localStorage.clear();
                        window.location.reload(true);
                    }
                });
    }, []);
    return (
        <>
            <div className="row mt-5 pb-10 border-bottom">
                <div className="col-lg-2">
                    <p className="font-size-h6 font-weight-bold mb-0">{language.LAB_LAB}</p>
                </div>
                <div className="col-lg-10">
                    {LabData.map((item) => (
                        <label key={item.id} className="search_btn_check">
                            <Field type="checkbox" name="Lab" id={`${item.lab}_lab`} value={item.lab}/>
                            <div className="labBox selectBox">
                                <span>{item.lab}</span>
                            </div>
                        </label>
                    ))}
                </div>
            </div>
        </>
    );
};