/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from 'react-router-dom';

// import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../_helpers";
export const MockDocTableColumn = (conversionrate,symbol,history,RouteToProforma,data,language) => [
    {
        Header: language.CONFIRM_DIAMOND_ORDERNO,
        className: "align-middle text-center",
        accessor: (d) => {
            return (
                <>
                    <span>{d.conform_good_id}</span>
                    <p className="m-0 small text-muted">{d.created_date}</p>
                </>
            );
        },
    },
    {
        Header: language.CONFIRM_DIAMOND_DIAMONDDETAILS,
        accessor: (d) => (
            <>
                <div className="row">
                    <div className="col-2 text-center p-0">
                        {d.aws_image &&  d.aws_image !=="null" ? (
                            <img
                                className="img-fluid mr-2"
                                alt={d.C_Shape}
                                src={d.aws_image}
                                width="50px"
                            />
                        ) : (
                            <img
                                className="img-fluid mr-2"
                                alt={d.C_Shape}
                                src={toAbsoluteUrl(
                                    `/media/shape/${d.C_Shape.toLowerCase()}-no.png`
                                )}
                                width="50px"
                                style={{ backgroundColor: "#f4f4f4" }}
                            />
                        )}
                    </div>
                    <div className="col-10 pl-2 p-0 text-left">
                        <div className={`mb-1 ${d.diamond_type === "N" ? "natTab" : "labTab"}`}>
                            <span className={`${ d.diamond_type === "N" ? d.C_Color === "fancy" ? "F" : "W" : d.C_Color === "fancy" ? "LF" : "L"  }_diamond mr-3 small`}>
                                {d.diamond_type === "N" ? d.C_Color === "fancy"  ? "F" : "W" : d.C_Color === "fancy" ? "LF" : "L"}
                            </span>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                className="text-primary mx-2 small"
                                href={
                                    d.certificate_link
                                }
                            >
                                {d.Lab}-{d.Certi_NO}
                            </a>
                            <span onClick={() => history.push({ pathname: `/detail/${d.diamond_type === "N" ? "N" : "L"}-${d.Stock_NO}` })} className={`text-primary cursor-pointer small ml-2`}>
                                {d.diamond_type === "N" ? "N" : "L"}-{d.Stock_NO}
                            </span>
                        </div>

                        <span className="text-dark font-weight-bold d-block">
                            {d.C_Shape}{" "}
                            {d.C_Weight}{" "}
                            {d.C_Color === "fancy" ? (
                                <>
                                    {d.f_intensity}{" "}{d.f_overtone}{" "}{d.f_color}{" "}
                                </>
                            ) : (
                                <>{d.C_Color}{" "}</>
                            )}
                            {d.C_Clarity}{" "}{d.C_Cut}{" "}{d.C_Polish}{" "}{d.C_Symmetry}{" "}
                            {d.C_Fluorescence}
                        </span>
                    </div>
                </div>
            </>
        ),
        className: "text-center align-middle",
        disableSortBy: true,
    },
    // {
    //     Header:'Tag',
    //     className:"text-center align-middle mr-3",
    //     Cell: (row) => {
    //         return  <div style={{ maxWidth: "180px"}}>
    //             <div className="label label-outline-primary label-pill label-inline bg-primary-o-20 mr-1">NO SHADE</div>
    //             <div className="label label-outline-success label-pill label-inline bg-success-o-20 mr-1">EYECLEAN</div>
    //             <div className="label label-outline-info label-pill label-inline bg-info-o-20 mr-1">MILKY</div>
    //         </div>;
    //       }
    // },
    {
        Header: language.CONFIRM_DIAMOND_LOCATIONANDDELIVERY,
        accessor: (d) => (
            <>
                <span calssName="font-weight-bolder">{d.country}</span>
                <br />
                <span className="text-muted small">7 days</span>
            </>
        ),
        className: "text-center align-middle",
        disableSortBy: true,
    },
    {
        Header: language.CONFIRM_DIAMOND_STATUS,
        accessor: (d) => (
            <div>
                <div
                    className={`label ${
                            d.Dimond_Status === "Order Approve" ? "label-primary"
                            : d.Dimond_Status === "Pending" ? "label-warning"
                            : d.Dimond_Status === "Ready For Invoice" ? "label-success" 
                            : d.Dimond_Status === "Ready to Ship" ? "label-success" 
                            : d.Dimond_Status === "Rejected" ? "label-danger" 
                            : d.Dimond_Status === "QC Rejected" ? "label-danger" 
                            : d.Dimond_Status === "QC Review" ? "label-warning" 
                            : d.Dimond_Status === "QC Approved" ? "label-success" 
                            : ""
                    } label-inline  mr-2`}
                >
                    {d.Dimond_Status}
                </div>
            <br />
            {/* {d.perfoma_no ? <Link to={{pathname:"proforma-detail",query:{perfoma_no:d.perfoma_no,conform_good_id:d.conform_good_id}}}  className="label label-light-info label-inline label-sm small" >Proforma generated</Link> : ""} */}
            {d.perfoma_no ? <a onClick={() => RouteToProforma({query:{perfoma_no:d.perfoma_no,conform_good_id:d.conform_good_id}},data)} className="label label-light-info label-inline label-sm small">Pro Forma Inv No {d.perfoma_created_at}</a> : ""}
        
        </div>
        ),
        className: "text-center align-middle",
       
    },
    // {
    //     Header:'Diamond Journey',
    //     accessor:d => (
    //         <>
    //             <span calssName="font-weight-bolder">{d.canada_mark}</span><br />
    //             <span className="text-muted small">1-2 days</span>
    //         </>
    //       ),
    //     className:"text-center align-middle",
    // },
    {
        Header: language.CONFIRM_DIAMOND_DISCOUNT,
        className: "text-center text-danger align-middle",
        accessor: (d) => Number(d.discount_main).toFixed(2),
        sortMethod: (a, b) => Number(a) - Number(b),
        Cell: (row) => {
            return <span className={`${(row.value >= 0) ? 'text-success' : 'text-danger'}`}>{row.value}%</span>;
        },
    },
    {
        Header: language.CONFIRM_DIAMOND_OURPRICECT,
        accessor: (d) => (
            <>
                <span calssName="font-weight-bolder text-primary">
                    ${d["OurPrice(INR)"]}
                </span>{" "}
                <br />
                <span className="text-muted small">
                    $/CT {d["INR/Ct"]}
                </span>
            </>
        ),
        className: "text-right pr-12 align-middle",
    },
    {
        Header: language.CONFIRM_DIAMOND_TRACK,
        accessor: (d) => (
            <>
                <span calssName="font-weight-bolder">
                {d["track"]}
                </span>
               
            </>
        ),
        className: "text-center align-middle",
    },
    // {
    //     Header:'Payment Status',
    //     accessor:'CUT',
    //     className:"text-center align-middle",
    // }
];
